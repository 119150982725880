import React from 'react';
import '../Lega.css';
import '../App.css';
import {NavigationBar} from './NavigationBar';
import {AbstractComponent} from './AbstractComponent';
import {gettingJobHistoryError, legaCheckFailed, legaCheckSuccess, mailToKbiError, notAuthorized, requestCourseFailed, requestCourseSuccess} from '../model/toasts.model';
import {CountlyService} from "../services/CountlyService";
import {LegaCheckService} from "../services/LegaCheckService";

export class LegaCheck extends AbstractComponent {

    legaCheckService = LegaCheckService.get();
    countly = CountlyService.get();

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        super.componentDidMount();

        if (window.location.pathname === '/lega-check') {
            if (this.hasViewJobHistoryAuthority()) {
                this.legaCheckService.getJobHistory()
                    .then(res => {
                        this.setState({
                            jobHistoryList: res.data,
                        })
                    })
                    .catch(() => {
                        this.props.toast.showToast(gettingJobHistoryError)
                    })
            }
        } else {
            let params = window.location.pathname.split('/lega-check')[1];
            let paramsSplit = params.split('/');
            if (paramsSplit[1] === 'request-course') {
                let courseNumber = paramsSplit[2];
                this.countly.requestingCourse(courseNumber)
                this.legaCheckService.requestCourse(courseNumber)
                    .then(() => {
                        this.props.toast.showToast(requestCourseSuccess)
                        this.countly.requestCourseSuccess(courseNumber)
                    })
                    .catch(() => {
                        this.props.toast.showToast(requestCourseFailed)
                        this.countly.requestCourseFailed(courseNumber)
                    })
            } else if (paramsSplit[1] === 'mailto-kbi') {
                let courseNumber = paramsSplit[2];
                this.countly.getMailToKbiLink(courseNumber)
                this.legaCheckService.getMailToKbiLink(courseNumber)
                    .then(res => {
                        this.setState({
                            mailToKbi: res.data,
                            mailToKbiCourseNumber: courseNumber
                        })
                    })
                    .catch(() => {
                        this.props.toast.showToast(mailToKbiError)
                    })
            }
        }
    }

    hasTriggerJobAuthority() {
        return super.hasAuthority('TRIGGER_LEGA_JOB')
    }

    hasViewJobHistoryAuthority() {
        return super.hasAuthority('VIEW_LEGA_JOB_HISTORY')
    }

    hasSendMailToKbiAuthority() {
        return super.hasAuthority('SEND_MAIL_TO_KBI')
    }

    requiredAuthority() {
        return undefined;
    }

    render() {
        return (
            <div>
                <NavigationBar vendorModal={this.props.vendorModal}/>
                <div className='App-content'>
                    {this.renderTriggerJobButton()}
                    {this.renderMailToKbiButton(this)}
                    {this.renderJobHistory()}
                </div>
            </div>
        );
    }

    renderTriggerJobButton() {
        if (this.hasTriggerJobAuthority()) {
            return (
                <div>
                    <button
                        className="btn btn-primary create-button"
                        onClick={() => this.triggerLegaCheck()}>
                        Trigger Lega Checker
                    </button>
                </div>
            )
        } else {
            return <span/>
        }
    }

    renderMailToKbiButton(rootObject) {
        if (this.hasSendMailToKbiAuthority() && this.state.mailToKbi !== undefined) {

            return (
                <div>
                    <a className="btn btn-primary create-button" href={ rootObject.state.mailToKbi } onClick={() => rootObject.countly.createMailToKbi(rootObject.state.mailToKbiCourseNumber)}>Erstelle E-Mail an KBM Ausbildung</a>
                </div>
            )
        } else {
            return <span/>
        }
    }

    renderJobHistory() {
        if (this.hasViewJobHistoryAuthority() && this.state.jobHistoryList !== undefined) {
            return (
                <div>
                    <h2 className="job-history-header">Job History</h2>
                    <div className="row col-12">
                        <div className="col-2"><strong>Zeitstempel</strong></div>
                        <div className="col-4"><strong>offene Kurse</strong></div>
                        <div className="col-6"><strong>Benachrichtigungen</strong></div>
                    </div>
                    {this.state.jobHistoryList.map(job => this.renderJob(job))}
                </div>
            )
        } else {
            return <span/>
        }
    }

    renderJob(job) {
        return (
            <div key={job.id} className="row col-12">
                <div className="col-2">{job.startTime}</div>
                <div className="col-4">{job.openCourses.map(course => this.renderCourse(course))}</div>
                <div className="col-6">{job.sentInformations.map(info => this.renderSentInformation(info))}</div>
            </div>
        );
    }

    renderCourse(course) {
        return (
            <div>
                <div><strong>{course.name}</strong></div>
                <div>Ort: {course.location}</div>
                <div>Datum: {course.startDate} - {course.endDate}</div>
                <div>Freie Pl&auml;tze: {course.freeSeats}</div>
                <div>&nbsp;</div>
            </div>
        );
    }

    renderSentInformation(info) {
        return (
            <div>
                <div>An: {info.recipientUser.displayname}</div>
                {info.sentToTelegramSuccess ? <div>Telegram: {info.sentToTelegramId}</div> : <span/>}
                {info.sentToMailSuccess ? <div>Mail: {info.sentToMailAddress}</div>: <span/>}
                <div>Kurse: {info.openCourses.map(course => <div>{course.name}</div>)}</div>
                <div>&nbsp;</div>
            </div>
        );
    }

    triggerLegaCheck() {
        if (this.hasTriggerJobAuthority()) {
            this.legaCheckService.triggerLegaCheck()
                .then(res => {
                    this.props.toast.showToast(legaCheckSuccess);
                })
                .catch(err => {
                    this.props.toast.showToast(legaCheckFailed);
                });
        } else {
            this.props.toast.showToast(notAuthorized);
        }
    }
}
