export const loginFailed = "Login fehlgeschlagen";
export const appInitiated = "App wurde initialisiert";
export const deleteUserSuccess = "Benutzer gelöscht";
export const saveUserSuccess = "Benutzer gespeichert";
export const deleteCourseSuccess = "Kurs gelöscht";
export const saveCourseSuccess = "Kurs gespeichert";
export const didNotModify = "Keine Änderung";
export const notAuthorized = "Nicht berechtigt";
export const saveSettingsSuccess = "Einstellungen gespeichert";
export const legaCheckSuccess = "Lega-Checker Job erfolgreich beendet";
export const legaCheckFailed = "Lega-Checker Job fehlgeschlagen ";
export const triggerPasswordResetSuccess = "Passwort vergessen E-Mail wurde versandt";
export const triggerPasswordResetFailed = "E-Mail konnte nicht versandt werden";
export const passwordResetSuccess = "E-Mail mit neuem Password wurde versandt";
export const passwordResetFailed = "Zurücksetzen des Passworts fehlgeschlagen";
export const requestCourseSuccess = "Kursteilnahme angefragt";
export const requestCourseFailed = "Anfrage Kursteilnahme fehlgeschlagen";
export const mailToKbiError = "Fehler beim Laden des MailTo KBI Links";
export const gettingJobHistoryError = "Job History konnte nicht geladen werden";

export const commonError = "Ein Fehler ist aufgetreten";
