export const apiRoute = "/api";

export const authenticate = "/authenticate";
export const authenticated = "/authenticated";
export const logout = "/logout";

export const course = "/lega/course/";
export const settings = "/lega/settings";
export const allSettings = "/lega/settings/all";
export const currentUser = "/user/current";
export const user = "/user/";
export const role = "/role/";
export const checkAppSetup = "/ops/check";
export const triggerPasswordReset = "/ops/user/USERNAME/reset-password";
export const resetPassword = "/ops/user/USERNAME/reset-password/TOKEN";
export const triggerLegaChecker = "/lega/check";
export const requestCourse = "/lega/course/COURSE_NUMBER/request-course";
export const getMailToKbiLink = "/lega/course/COURSE_NUMBER/mailto-kbi";
export const getJobHistory = "/lega/history";
